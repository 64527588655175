<template>
  <div id="withdrawals" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <SdInput
            type="text"
            label="Search"
            v-model="searchName"
            placeholder="Name"
          />
        </div>
        <div class="column is-12">
          <b-table
            :data="data.length > 0 ? data : withdrawals"
            :paginated="isPaginated"
            hoverable
          >
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ withdrawals.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Name" v-slot="props">{{
              props.row.student.name
            }}</b-table-column>

            <b-table-column label="School Class" v-slot="props">
              {{ props.row.student.schoolClass.name }}
            </b-table-column>

            <b-table-column label="Date" v-slot="props">{{
              props.row.createdAt
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-transport-route"
      :context="context"
      @remove="remove"
      @restore="remove"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add" v-if="context === 'restore'"
        >You want to restore this student? Proceed?</template
      >
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'withdrawals',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Withdrawals',
      data: [],
      withdrawals: [],
      id: null,
      context: '',
      isPaginated: true,
      searchName: '',
    }
  },
  watch: {
    searchName(name) {
      this.data = this.withdrawals.filter(
        (withdrawal) =>
          withdrawal.student.name
            .split(' ')[0]
            .toLowerCase()
            .match(name.toLowerCase()) ||
          withdrawal.student.name
            .split(' ')[1]
            .toLowerCase()
            .match(name.toLowerCase())
      )
    },
  },
  methods: {
    initializeInfo(withdrawal) {
      // this.$router.push(
      //   `/school/${this.schoolId}/withdrawal_info/${withdrawal.id}`
      // )
    },
    initializeUpdate(withdrawal) {
      this.context = 'restore'
      this.id = parseInt(withdrawal.id)
      this.openModal()
    },
    initializeRemove(withdrawal) {
      this.context = 'remove'
      this.id = parseInt(withdrawal.id)
      this.openModal()
    },
    remove() {
      if (this.context === 'remove') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation deleteWithdrawnQuery($id: Int!) {
                deleteWithdrawn(input: { id: $id }) {
                  errors
                }
              }
            `,
            variables: {
              id: this.id,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.deleteWithdrawn.errors,
              'Successfully deleted.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.withdrawals.refetch()
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else if (this.context === 'restore') {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation restoreWithdrawnQuery($id: Int!) {
                restoreWithdrawn(input: { id: $id }) {
                  errors
                }
              }
            `,
            variables: {
              id: this.id,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.restoreWithdrawn.errors,
              'Successfully restored.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.withdrawals.refetch()
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [])
    this.$apollo.addSmartQuery('withdrawals', {
      query: gql`
        query withdrawals($schoolId: Int!) {
          withdrawals(schoolId: $schoolId) {
            id
            student {
              name
              schoolClass {
                name
              }
            }
            createdAt
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.withdrawals.refetch()
  },
}
</script>
